<template>
    <Page
        title="Connect QB Company"
        icon="mdi-pipe"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
        :show-footer="true"
    >
        Connect to a
        <span class="secondary--text">Property Company</span>
        you have already created in Quickbooks

        <v-layout slot="actions">
            <btn color="success" @click="doConnectQBCompany" small>Do it!</btn>
        </v-layout>
    </Page>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            showSignUp: true,
            email: '',
            password: '',
            isShowError: false,
            errorMessage: '',
            isShowLoading: false,
            loadingMessage: '',
        };
    },
    methods: {
        ...mapActions(['connectQBCompany']),
        async doConnectQBCompany() {
            this.loadingMessage = 'Connecting to Quickbooks';
            this.isShowLoading = true;
            try {
                const redirectTo = await this.connectQBCompany();
                console.log('redirectTo', redirectTo);
                window.location = redirectTo;
            } catch (e) {
                console.log('Error Connecting Company ', e);
                this.isShowLoading = false;
            }
        },
    },
    created() {
        if (this.$route.query.errmsg) {
            // this.errorMessage = this.$route.query.errmsg;
            // this.isShowError = true;
            console.log('fix this - error has occurred', this.$route.query.errmsg);
        }
    },
};
</script>
